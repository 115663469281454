import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const channelBaseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;
const reportsBaseUrl = process.env.VUE_APP_REPORTS_SERVER_URL;

// UPLOAD MTR SECTION 

export const getChannelList = (companyId) => {
    const uri = `${channelBaseUrl}/api/v1/companies/channels`;  
    return apiHelper.get(uri);
  };

export const getBTwoBList = () => {
    const uri = `${reportsBaseUrl}/api/v1/reports/mtr-report-type`;
    return apiHelper.get(uri);
  };

export const getViewMTRList = ( channelIds, reportType, date, pageNumber = 0, pageSize = 2, companyId) => {

    let uri = `${reportsBaseUrl}/api/v1/reports/companies/mtr-filter?page-no=${pageNumber}&page-size=${pageSize}`;

    if(channelIds && channelIds.length > 0){
        uri = uri.concat("&channel-id=",channelIds)
    }
    if(reportType && reportType.length > 0){
        uri = uri.concat("&report-type=", reportType)
    }
    if(date){
        uri = uri.concat("&date=", date)
    }

    return apiHelper.get(uri);
  };

//   upload mtr data  date
export const uploadMTR = (formData, companyId) => {
    const uri = `${reportsBaseUrl}/api/v1/reports/upload`;
    return apiHelper.post(uri, formData);
  }

//   VIEW MTR SECTION

export const getViewReportDetail = (reportId, companyId) => {
    const uri = `${reportsBaseUrl}/api/v1/reports/companies/report-id/${reportId}`;
    return apiHelper.get(uri);
  };

export const getDownloadViewMTR = (reportId, companyId) => {
    let uri = `${reportsBaseUrl}/api/v1/reports/companies/reports/${reportId}/download`;
    return apiHelper.downloadViewMtrFile(uri);
  }