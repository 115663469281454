<template>
  <div class="whithout-tab-content">
    <div v-if="!isUploadMTR">
       <b-card class="m-2" :body-class="'card-padding'" v-if="isFilter">
         <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="openDesktopFilterEvent">
        <div class="row">
          <div class="col-md-3 mt-auto">
            <TreeDropdown
              :labelText="'Channels'"
              class="mt-2"
              :rootOptions="channelListOptions"
              :selectedValue="selectedChannelList"
              @selectedField="getSelectedChannel($event)"
            />
          </div>
          <div class="col-md-3 mt-auto">
            <TreeDropdown
              :labelText="'B2B/B2C'"
              class="mt-2"
              :rootOptions="bTwobOptionList"
              :selectedValue="selectedBtwoBList"
              @selectedField="getSelectedBtwoB($event)"
            />
          </div>
          <div class="col-md-3 mt-auto gst-datepicker">
            <p class="tree-label mb-2">Month and Year</p>
            <date-picker
              v-model="selectedMonth"
              lang="en"
              type="month"
              format="MMM-YYYY"
              confirm
              @clear="clearMonthYear($event)"
              @confirm="selectedMonthYear($event)"
            >
            </date-picker>
          </div>
        </div>
      </b-card>

      <!-- <b-card class="cards m-2 view-mtr-gst"> -->
        <w-table
          :tableProperties="tableProperties"
          @viewMtrReportTable="viewMtrReportTable($event)"
          :tableLoading="tableLoading"
          :isViewMTR="true"
          @getSelectedPageEvent="getSelectedPage($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          :isFilterButton="true"
          @openDesktop_filterEvent="openDesktopFilterEvent"
          :shipmentType="'uploadMtr'"
          @generateReport="showUploadMTR"
        />
      <!-- </b-card> -->
    </div>
    <div v-if="isUploadMTR">
        <div class="row p-0 m-0 w-100 my-4">
          <div class="col-6 p-0 m-0 generate-title" >
              Upload MTR 
          </div>
          <div class="col-3 p-0 m-0 ml-auto back-button" @click="backToMTR">
          <img src="../../assets/SVG/Back.svg" width="60px">
          </div>
      </div>
      <div>
        <UploadMtr @backToMTR="backToMTR"/>
      </div>
    </div>
   

    <w-loading-spinner :loadSpinner="loadingWidget" />

    <w-modal
      :trigger="showModal || isTablePopUp"
      :title="isTablePopUp ? 'View' : 'HectoCommerce'"
      :centered="true"
      :isViewMtrs="isTablePopUp"
      :alert="showModal"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :viewModalTableData="viewMtrTableData"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      @eventClose="closeModal()"
      @downloadMTRView="downloadViewMTR()"
    />
  </div>
</template>

<script>
import moment from "moment";
import TreeDropdown from "../../widgets/TreeDropdown";
import DatePicker from "vue2-datepicker";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import Modal from "../../widgets/ModalWidget";
import {
  getChannelList,
  getBTwoBList,
  getViewMTRList,
  getViewReportDetail,
  getDownloadViewMTR,
} from "../../Service/GstService";
import UploadMtr from "./UploadMtr.vue";
import moment_timezone from "moment-timezone";

export default {
  components: {
    TreeDropdown,
    DatePicker,
    "w-table": TableWithCheckbox,
    "w-loading-spinner": LoadingSpinner,
    "w-modal": Modal,
    UploadMtr
  },

  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      isTablePopUp:false,
      showModal: false,
      alertType: "",
      // alert: false,
      alertMsg: "",
      loadingWidget: false,
      tableLoading: false,
      channelListOptions: [],
      selectedChannelList: [],
      bTwobOptionList: [],
      selectedBtwoBList: [],
      selectedMonth: "",
      viewMtrReportID: "",
      viewMtrTableData: {},

      tableProperties: {
        items: [],
        fields: [
          {
            key: "reportDate",
            label: "Month",
          },
          {
            key: "uploadedDate",
            label: "Uploaded Date"
          },
          {
            key: "channelName",
            label: "Channel",
          },
          {
            key: "reportType",
            label: "Report Type",
          },
          {
            key: "view_mtr_btn",
            label: "View",
          },
        ],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
      isFilter:false,
      isUploadMTR:false
    };
  },

  created() {
    this.getChannelList();
    this.getBTwoBList();
    this.getViewMTRList();
  },

  methods: {
    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelListOptions.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.showModal = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          // this.isConfirmAlert = false;
        });
    },

    getBTwoBList() {
      getBTwoBList()
        .then((res) => {
          let resp = res.response;
          if (resp) {
            resp.forEach((element) => {
              this.bTwobOptionList.push({
                id: element,
                label: element,
              });
            });
          }
        })
        .catch((err) => {
          this.showModal = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          // this.isConfirmAlert = false;
        });
    },

    getViewMTRList() {
      this.tableLoading = true;
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset)
      getViewMTRList(
        this.selectedChannelList,
        this.selectedBtwoBList,
        this.selectedMonth,
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.companyID,
      )
        .then((res) => {
          this.tableLoading = false;
          let resp = res.response.content;
          console.log("view mtre data ", resp)
          resp.map(res=>{
            res.uploadedDate = res.uploadedDate ? this.getDateFormat(res.uploadedDate)+` (${timeZone})`:' - ';
            this.tableProperties.items.push(res);
          })
          // this.tableProperties.items = resp;
          this.tableProperties.totalRows = res.response.totalElements;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.showModal = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          // this.isConfirmAlert = false;
        });
    },
    getDateFormat(dateAndTime){
      let time = moment.utc(dateAndTime,'DD-MM-YYYY hh:mm:ss').local().format('DD/MM/YYYY hh:mm A');
      return time;
    },

    // show view report detail in MODAL

    viewMtrReportTable(reportId) {
      this.viewMtrReportID = reportId;
      getViewReportDetail(this.viewMtrReportID, this.companyID)
        .then((res) => {
          this.viewMtrTableData = res.response;
          // this.showModal = true;
          this.isTablePopUp = true;
        })
        .catch((err) => {
          this.showModal = true;
          this.isTablePopUp = false;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          // this.isConfirmAlert = false;
        });
    },

    // download view mtr file

    downloadViewMTR() {
      this.loadingWidget = true;
      getDownloadViewMTR(this.viewMtrReportID, this.companyID)
        .then((res) => {
          this.loadingWidget = false;
          this.isTablePopUp = false;
        })
        .catch((err) => {
          var self = this;
    // for read the blob response type 
          var myReader = new FileReader();
          myReader.addEventListener("loadend", function(e) {
            self.alertMsg = JSON.parse(e.srcElement.result).detail;
            console.log("inside",self.alertMsg)
          });
          //start the reading process.
          myReader.readAsText(err);
          this.loadingWidget = false;
          this.isTablePopUp = false;
          this.showModal = true;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    clearMonthYear() {
      this.selectedMonth = "";
      this.getViewMTRList();
    },
    selectedMonthYear() {
      this.selectedMonth = moment(this.selectedMonth).valueOf();
      this.getViewMTRList();
    },

    getSelectedChannel(event) {
      this.selectedChannelList = event;
      this.getViewMTRList();
    },

    getSelectedBtwoB(event) {
      this.selectedBtwoBList = event;
      this.getViewMTRList();
    },

    // for pagination
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getViewMTRList();
    },

    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getViewMTRList();
    },
    backToMTR(){
      this.isUploadMTR = !this.isUploadMTR
    },
    // close modal
    closeModal() {
      this.showModal = false;
      this.isTablePopUp = false;
    },

    openDesktopFilterEvent(){
      this.isFilter = !this.isFilter
    },
    showUploadMTR(){
      this.isUploadMTR = !this.isUploadMTR
    }
  },
};
</script>

<style scoped>
.view-mtr-gst ::v-deep table thead tr th {
  text-align: center;
}
.view-mtr-gst ::v-deep table tbody tr td {
  text-align: center;
}
.view-mtr-gst ::v-deep table thead tr th:first-child {
  text-align: left;
}
.view-mtr-gst ::v-deep table tbody tr td:first-child {
  text-align: left;
}
.back-button{
    text-align: right;
    color:#2E8CD3;
    font-size: 14px;
    cursor: pointer;
} 
.generate-title{
    color: #1B1C1D;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width:768px) {
  .whithout-tab-content{
    position: relative;
    top: -40px;
  }
  .gst-datepicker ::v-deep .mx-datepicker-popup{
    top: 20px !important;
  }
  .gst-datepicker ::v-deep .mx-datepicker{
    width: 100% !important;
  }
}
</style>
