<template>
  <div>
    <!-- <b-tabs content-class="mt-0" ref="tabs" class="report-tabs" v-model="tabIndex" align="center">
      <b-tab title="UPLOAD MTR" class="stock " active>
        <UploadMtr v-if="tabIndex == 0" />
      </b-tab>
      <b-tab title="VIEW MTRs" class="listing">
        <ViewMtrs v-if="tabIndex == 1" />
      </b-tab>
    </b-tabs> -->
    <ViewMtrs />
  </div>
</template>

<script>
import UploadMtr from "../components/Gst/UploadMtr.vue";
import ViewMtrs from "../components/Gst/ViewMtr.vue";
export default {
    components:{
        UploadMtr,
        ViewMtrs
    },
    data() {
    return {
      tabIndex: 0,
    };
  },
}
</script>

<style scoped>

.cards {
  margin-top: 20px;
}
</style>