<template>
  <div class="gst-upload">
    <b-card class="my-2 m-0" :body-class="'card-padding'">
      <div class="row">
        <div class="col-md-3 mt-auto">
          <TreeDropdown
            :labelText="'Channels'"
            class="mt-2"
            :rootOptions="channelListOptions"
            :selectedValue="selectedChannelList"
            @selectedField="getSelectedChannel($event)"
          />
        </div>
        <!-- <div class="col-md-3 mt-auto">
          <TreeDropdown
            :labelText="'B2B/B2C'"
            class="mt-2"
            :rootOptions="bTwobOptionList"
            :selectedValue="selectedBtwoB"
            @selectedField="getSelectedBtwoB($event)"
          />
        </div> -->

        <div class="col-md-3 mt-auto ">
          <label class="p-0 b2b-label">B2B/B2C</label>
          <w-dropdown
            :label-text="''"
            :labelId="'b2b-b2c'"
            :selectedValue="selectedBtwoB"
            :selectOption="bTwobOptionList"
            @selectedOption="getSelectedBtwoB($event)"
          />
        </div>

        <div class="col-md-3 mt-auto">
          <p class="tree-label mb-2">Month and Year</p>
          <date-picker
            style="width:100%"
            v-model="selectedMonth"
            lang="en"
            type="month"
            format="MMM-YYYY"
            confirm
            @clear="clearMonthYear($event)"
            @confirm="selectedMonthYear($event)"
            id="month-year"
          >
          </date-picker>
        </div>
        <div class="col-md-3 mt-auto select-file">
          <p class="tree-label mb-2">File Upload</p>
          <b-form-file
            id="file-upload"
            ref="file-input"
            @change="previewFiles($event)"
            accept=".csv"
          ></b-form-file>
        </div>
      </div>

      <div class="row submit-btn mt-5 mb-1">
        <w-button-input @buttonClicked="uploadMTRData" :disabled="isSubmitBtnDisabled" :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn'" />
        <w-button-input @buttonClicked="backToMTR" :label="'Cancel'" :buttonStyle="'auth-button-style'" :buttonClass="'shipment-buttons'" />
      </div>
    </b-card>

    <!-- modal for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import moment from "moment";
import TreeDropdown from "../../widgets/TreeDropdown";
import Dropdown from "../../widgets/Dropdown.vue";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import ButtonInput from "../../widgets/InputButton.vue";
import {
  getChannelList,
  getBTwoBList,
  uploadMTR,
} from "../../Service/GstService";

export default {
  components: {
    TreeDropdown,
    "w-dropdown": Dropdown,
    DatePicker,
    Modal,
    "w-loading-spinner": LoadingSpinner,
    "w-button-input":ButtonInput
  },

  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      loadingWidget: false,

      channelListOptions: [],
      selectedChannelList: [],
      bTwobOptionList: [],
      selectedBtwoB: "",
      selectedFile: null,
      selectedMonth: "",
    };
  },

  created() {
    this.getChannelList();
    this.getBTwoBList();
  },
  computed: {
    isSubmitBtnDisabled() {
      if (
        this.selectedChannelList.length != 0 &&
        !this.selectedBtwoB == "" &&
        !this.selectedMonth == "" &&
        this.selectedFile !== null
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    previewFiles(event) {
      let fileValidate = event.target.files[0];
      // Allowing file type
      const validateExtention = (file = {}) => {
        const ALLOWED_EXTENTIONS = ["csv"];
        const fileName = file.name || "";
        const extention = fileName
          .substr(fileName.lastIndexOf(".") + 1)
          .toLowerCase();
        return !(ALLOWED_EXTENTIONS.indexOf(extention) === -1);
      };
      this.selectedFile = null;
      if (validateExtention(fileValidate)) {
        this.selectedFile = fileValidate;
      } else {
        this.$refs["file-input"].reset();
      }
    },

    //    upload files
    uploadMTRData() {
      this.loadingWidget = true;
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("report-type", this.selectedBtwoB);
      formData.append("channel-id", this.selectedChannelList);
      formData.append("period", this.selectedMonth);

      uploadMTR(formData, this.companyID)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = res.response || "Successfully Uploaded";
          this.alertType = "Success";
          this.isConfirmAlert = false;
          // reset form field
          this.$refs["file-input"].reset();
          this.selectedMonth = "";
          this.selectedBtwoB = "";
          this.selectedChannelList = [];
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.failureMessage || "Oops something went wrong?";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelListOptions.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getBTwoBList() {
      this.bTwobOptionList = [];
      this.bTwobOptionList.push({
        value: "",
        text: "Select",
      });
      getBTwoBList()
        .then((res) => {
          let resp = res.response;
          if (resp) {
            resp.forEach((element) => {
              this.bTwobOptionList.push({
                value: element,
                text: element,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    clearMonthYear() {
      this.selectedMonth = "";
    },
    selectedMonthYear() {
      this.selectedMonth = moment(this.selectedMonth).valueOf();
    },

    getSelectedChannel(event) {
      this.selectedChannelList = event;
    },

    getSelectedBtwoB(event) {
      this.selectedBtwoB = event;
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    backToMTR(){
      this.$emit("backToMTR");
    }
  },
};
</script>

<style scoped>
.gst-upload ::v-deep .custom-file-label{
  height: 37.5px;
}
.gst-upload ::v-deep .custom-file-label::after{
  height: 35.7px;
}
.gst-upload ::v-deep .custom-file-input{
  cursor: pointer;
}
.card-padding {
  box-shadow: 0px 1px 3px #00000029;
}
.b2b-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
  margin-bottom: 6px;
}
.gst-upload ::v-deep .custom-select {
  height: calc(1.5em + 0.75rem + 5px);
}
.gst-upload ::v-deep .form-control-file {
  height: 35px;
}
.submit-btn {
  display: flex;
  justify-content: center;
}
.submit-btn ::v-deep .shipment-buttons{
  margin-top: 0 !important;
  margin-left: 20px;
  justify-content: center;
}

@media screen and (min-width:768px) and (max-width:800px) {
   ::v-deep .mx-datepicker-popup{
    top: -150px !important;
  }
}
@media screen and (max-width:380px) {
  .submit-btn ::v-deep button {
    max-width: 8rem !important;
  }
}
</style>
